import {
  CUSTOMER_LIST_ROUTE_NAME,
  EMPLOYEE_ROUTE_NAME,
  EXPENSES_ROUTE_NAME,
  ORDDER_FORM_ROUTE_NAME,
  PRODUCING_ROUTE_NAME,
  PRODUCT_LIST_ROUTE_NAME,
  REVENUE_STATISTICS_ROUTE_NAME,
  SUPPLIES_LIST_ROUTE_NAME
} from '@/router';

export default [
  {
    title: 'RevenueStatistics',
    route: REVENUE_STATISTICS_ROUTE_NAME,
    icon: 'TrendingUpIcon'
  },
  {
    title: 'Coupon',
    route: '',
    icon: 'GiftIcon'
  },
  {
    title: 'Employee',
    route: EMPLOYEE_ROUTE_NAME,
    icon: 'UserIcon'
  },
  {
    title: 'Customer',
    route: CUSTOMER_LIST_ROUTE_NAME,
    icon: 'UsersIcon'
  },
  {
    title: 'Product',
    route: PRODUCT_LIST_ROUTE_NAME,
    icon: 'PackageIcon'
  },
  {
    title: 'Order',
    route: ORDDER_FORM_ROUTE_NAME,
    icon: 'ShoppingCartIcon'
  },
  {
    title: 'Producing',
    route: PRODUCING_ROUTE_NAME,
    icon: 'LayersIcon'
  },
  {
    title: 'Expenses',
    route: EXPENSES_ROUTE_NAME,
    icon: 'CompassIcon'
  }
];
